.rv-xy-plot {
    font-family: sans-serif;
  }

  .chart{
    // display: flex;
      height: 75px;
      // margin: 0px 15px 15px 15px;
      // flex: 1 0 21%; 
      // padding: 1rem;
    }
    .table1 {
      display: flex;
      height: 400px;
      margin: 15px;
      padding: 1rem;
      flex: 1.2 1 0;
    
    }
    .table2 {
      display: flex;
      height: 400px;
      margin: 15px;
      padding: 1rem;
    
      flex: 1.8 1 0;
    
    }
    .info {
      display: flex;
      height: auto;
      // margin: 15px;
      padding: 1rem;
      // flex: 1 0 41%; 
    }
    