
.search  {
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
        input {
            min-height: 45px;
            display: block;
            width: 100%;
            padding: 0.375rem 1rem;
            background-clip: padding-box;
            border: 1px solid $gold !important;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 18px !important;
            cursor: default;
            outline: none;
            background: $white;
            color: $text-dark;
            font-size: 0.9em;
        }
        .placeholder {
            color: hsl(0, 0%, 50%);
            margin-left: 2px;
            margin-right: 2px;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            box-sizing: border-box;
        }
    }
}


.drag-n-drop {
height: 100px;
max-width: 200px;
border-radius: 24px;
border: 1px solid $gold;
margin: 10px 0 ;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;

}
