


.p-icon-card {
  width: 42px;
  height: 42px;
  background-color: #302f2f;
  border-radius: 50%;
  background-image: url("https://picsum.photos/200/300/");
  background-size: cover;
  background-position: right;
  position: relative;
  

    .indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0%;
      right: 0%;
      left: 60%;
      bottom: 60%;
      background-color: white;
      color: $theme-main-red;
      border-radius: 50%;

    }
}


.theme-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  // box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}


.media-preview-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  // box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.082);
  height: 650px;
  background: $grey2;
  
}
.card {
  border: none !important;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  margin: 5px;
  padding: 1rem;
  .icon {
    vertical-align: middle;
    font-size: 24px;
  }

}
.card-header{
      background: $dark;
  display: flex;
  flex: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 75px;
  border-top-left-radius: 18px !important;
  border-top-right-radius: 18px !important;

}

.categories-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 18px !important;
  // box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.082);
  min-height: 450px;
  background: $grey2;
}
.add-category-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  // box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.082);
  min-height: 450px;
  background: $grey2;
  border-radius: 18px !important;

 
}















