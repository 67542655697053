.btn-primary {
  color: #ffffff;
  background-color: $theme-main;
  border-color: $theme-main;
  border-radius: 18px !important;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #94ff74;
  border-color: #94ff74;
}
.btn-primary:active {
  background-color: #94ff74;
  border-color: #94ff74;
}
.btn-primary:focus {
  background-color: #94ff74 !important;
  border-color: #94ff74 !important;
  box-shadow: 0 0 0 0.25rem rgba(247, 246, 245, 0.959) !important;

}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #ffffff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: none
}
.btn-primary:disabled {
  color: #ffffff;
  background-color: grey;
  border-color: grey;
}
.btn-info {
  border-radius: 18px !important;
  color: #fffdfd;
}
 .btn-info:focus {
   color: #ffffff;
   
  box-shadow: none
}
.btn-info:hover {
  color: white;
}
.btn-warning {
  border-radius: 18px !important;
  color: #fffdfd;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:focus {
  color: #fffdfd;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(247, 246, 245, 0.959) !important;
}
.btn-warning:hover {
  color: #fffdfd;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(247, 246, 245, 0.959) !important;
}



.btn-danger {
  background-color: $theme-main-red;
  border-color: $theme-main-red;
  border-radius: 18px;
  cursor: pointer;
}

.btn-danger:focus {
  color: #ffffff;
  box-shadow: 0 0 0 0.25rem rgba(247, 246, 245, 0.959) !important;
  background-color: $theme-main-red !important;
}
.btn-danger:hover{
  color: #ffffff;
  background-color: #fa6355;
  border-color: #fa6355;
  box-shadow: 0 0 0 0.25rem rgba(247, 246, 245, 0.959) !important;

}
.btn-danger:active{
  color: #ffffff;
  background-color: #fa6355;
  border-color: #fa6355;
}
.btn-danger:focus {
  background-color: #fa6355;
  border-color: #fa6355;
  box-shadow: 0 0 0 0.25rem rgba(247, 246, 245, 0.959) !important;
  
}


.btn-outline-success {
  color: #69d482;
  border-color: #69d482;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #69d482;
  border-color: #69d482;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #69d482;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #69d482;
  border-color: #69d482;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5);
}

.btn-alt {
  position: relative;
  background-color: #4caf50;
  border: none;
  border-radius: 18px;
  font-size: 28px;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}
.btn-alt:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.btn-alt:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}



.btn-burger {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  border: none;
  vertical-align: middle !important;
  border-radius: 18px;
  font-size: 28px;
  color: #ffffff;
  padding: 0 20px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}
.btn-burger:hover {
  background-color: transparent;
}
.btn-burger:active {
  background-color: transparent;
}
.btn-burger:focus {
  color: #ffffff;
  background-color: transparent !important;
  border-color: transparent;
  border: 0;
  box-shadow: none !important;
}

.btn-quantity {
  color: #ffffff;
  background-color: $theme-main;
  border-color: $theme-main;
  border-radius: 18px !important;
  height: 35px;
  width: 35px;
}
.btn-quantity:hover {
  color: #ffffff;
  background-color: $theme-main;
  border-color: $theme-main;
}
.btn-quantity:active {
  background-color: $theme-main;
  border-color: $theme-main;
}
.btn-quantity:focus {
  background-color: $theme-main;
  border-color: $theme-main;
}

.rounded {
  border-radius: 50% !important;
}

.pad-fix {
padding: 1rem;
}




// #order-tabs {

//     .nav-tabs .nav-link.active,
//     .nav-tabs .nav-item.show .nav-link {
//       color: $white;
//       background: $theme-main-dark;
//       border-color: #dee2e6 #dee2e6 #ffffff;

//     }
//     background: $theme-main-red !important;


// }
