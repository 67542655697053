// // @media (max-width: 1399px) {
// //   .nav-tabs .nav-link {
// //     font-size: 0.8em;
// //   }
// //   #orders .orders-list .order-wrapper .card-header h3,
// //   #orders .orders-list .order-wrapper .card-header .h3 {
// //     font-size: 1em !important;
// //   }

// //   .table-wrapper {
// //     table {
// //       //  display: flex;
// //       // justify-content: stretch;
// //       //  flex-direction: column;

// //       td,
// //       th {
// //         min-width: 90px !important;
// //       }
// //     }
// //   }
// }

@media (max-width: 1199px) {
  // .wrapper {
  //   margin-left: 200px;
  // }

  #orders .chip {
    width: 50px !important;

    span {
      display: none;
    }
  }
}

@media (max-width: 1090px) {
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
  #order-wrapper {
    width: 100%;
    margin: 0 auto;

    .order-menu {
      width: 100%;
      height: auto;
    }
  }

  table {
    height: 100%;
  }
  th,
  td {
    font-size: 0.6rem;

    min-width: 80px;
    font-weight: 600;
    img {
      width: 50px;
      height: auto;
    }
  }
  .wrapper {
    display: flex;
    width: 100% !important;
    height: auto;

    // margin-left: 70px !important;
    min-height: 100vh;
  }

  .card {
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 18px;
    text-align: center !important;
    display: flex;

    // align-items: center;
  }
  // .table-wrapper {
  //   overflow-y: scroll;
  //   overflow-x: hidden;
  //   -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  // }

  .card-title {
    font-size: 0.8em;
    text-align: center;
  }
}

@media (max-width: 649px) {
  #orders .orders-list .order-wrapper .order-menu {
    width: 100%;
    margin: 1.5rem auto 1.5rem auto;
    padding: 0.6rem;
  }
  #orders .chip {
    display: none !important;

    span {
      display: none;
    }
  }
  .delete_act {
    width: 100%;
    padding: 0.25em;
    margin: 0 auto;
    text-align: center;
  }
  .responsiveTable tbody tr {
    border: 0 !important;
    border-radius: 1.125em;
    background: $grey9;
    padding: 0.25em;
    margin-bottom: 2em;
  }
  // .table-wrapper th:last-child {
  //   display: none !important;
  //   width: 1px;
  // }
  .table-wrapper table {
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 40em) {
  .responsiveTable th:last-child {
    display: none !important;
  }
}
